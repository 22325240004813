import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Hero from './Components/Hero/Hero';
import FooterCTA from './Components/FooterCTA/FooterCTA';
import Carousel from './Components/Carousel/Carousel';
import Events from './Components/Events/Events';
import Team from './Components/Team/Team';
import ContactUs from './Components/ContactUs/ContactUs';
import OurMission from './Components/OurMission/OurMission';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

function App() {
  return (
      <Router>
        <div className="App">
          <Header />
            <Routes>
              <Route path='/' element={
                <>
                  <Hero />
                  <Carousel />
                </>
              } />
              <Route path='/events' element={<Events />} />
              <Route path='/contact' element={<ContactUs />} />
              <Route path='/team' element={<Team />} />
              <Route path='/our-mission' element={<OurMission />} />
            </Routes>
          <FooterCTA />
          <Footer />
        </div>
      </Router>
  );
}

export default App;
