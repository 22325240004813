import vid from '../../assets/videos/vid.mp4';
import vidWebm from '../../assets/videos/vid.webm';
import poster from '../../assets/videos/vid-poster.png';
import './ourmission.css';
import { useRef } from 'react';

const OurMission = () => {
    const vidRef = useRef(null);
    const controlRef = useRef(null);
    
    function handlePlay(){
        if(vidRef.current.paused){
            vidRef.current.play();
            controlRef.current.classList.add('hidden');
        } else {
            vidRef.current.pause();
            vidRef.current.currentTime =0;
            controlRef.current.classList.remove('hidden');
        }
    }

    return (
        <div className="container our-mission">
            <div className="wrapper wrapper-vid">
                <video onClick={handlePlay} ref={vidRef} poster={poster}>
                    <source src={vid} type="video/mp4" />
                    <source src={vidWebm} type="video/webm" />
                    Browser doesn't support the video
                </video>
                <div onClick={handlePlay} ref={controlRef} className="control"></div>
            </div>
            <div className="wrapper">
            </div>
        </div>
    )
}

export default OurMission;
