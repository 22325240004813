import Profile from '../Profile/Profile';
import founders from '../../data/founders';
import './contactus.css';
import { social } from '../../data/links';


const ContactUs = () => {
    return (
        <div className="container contact-us">
            <div className="wrapper">
                <div className="head">
                    <div className="text-dancing">Contact</div>
                    <h1 className="text-large">Proteally Online</h1>
                </div>
                <div className="details contact-pt">
                    {
                        social.map(link=>
                            <div key={link.name} className="link">
                                <a href={link.link}>
                                    <div className="img">
                                        <img src={link.icon} alt={link.name} />
                                    </div>
                                    <div className="text">
                                        {link.text}
                                    </div>
                                </a>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="wrapper">
                <div className="head">
                    <div className="text-dancing">Write to</div>
                    <h1 className="text-large">Our Team</h1>
                </div>
                <div className="details profiles">
                    {
                        founders.map(founder => <Profile key={founder.name} data={{...founder, description: <EmailButton email={founder.email}/>}} />)
                    }
                </div>
            </div>
        </div>
    )
}

const EmailButton = ({email}) => {
    return (
        <div className="email btn-tiny">
            <a href={"mailto:" + email}>{email}</a>
        </div>
    )
}

export default ContactUs;